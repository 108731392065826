// import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
// import ModelList from "../../components/ModelList"
import React from "react"
import Seo from "../../components/seo"

const ArtcorePage = () => {
 

  return (
    <Layout >
      <Seo title="Talman Artcore guitars" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Talman<br /> Artcore guitars</h1>
        
        <section className="flex flex-col items-center">
          <h2 className="text-2xl mt-28">coming soon...</h2>
        </section>
        
      </div>
    </Layout>
  )
}

export default ArtcorePage